exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-itinerary-enquiry-js": () => import("./../../../src/pages/itinerary-enquiry.js" /* webpackChunkName: "component---src-pages-itinerary-enquiry-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-enquiry-js": () => import("./../../../src/templates/enquiry.js" /* webpackChunkName: "component---src-templates-enquiry-js" */),
  "component---src-templates-itinerary-js": () => import("./../../../src/templates/itinerary.js" /* webpackChunkName: "component---src-templates-itinerary-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-stay-js": () => import("./../../../src/templates/stay.js" /* webpackChunkName: "component---src-templates-stay-js" */)
}

